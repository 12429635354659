<template>
  <Dialog :header="header"
          class="ticket-dialog"
          v-model:visible="computedVisible"
          :modal="true"
          :closable="true"
          :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '30vw'}">

    <div class="card" v-if="params">
      <div id="overlay" v-if="overlay"></div>

      <div class="p-fluid grid">
        <div class="venta field col-12 md:col-12">
          <Card>
            <template #title>
              <div class="title">
                Detalles
                <Button label="Ver ticket" icon="pi pi-eye" iconPos="left" @click="openViewTicketDialog(params)"
                        class="view-button"/>
              </div>
            </template>
            <template #content>
              <Label2 title="Fecha" :content="formatDateLong2(params.fecha)"/>
              <Label2 title="Sorteo" :content="params.sorteo_tabla"/>

              <Label2 title="Animal" :content="params.animal" v-if="params.animal"/>

              <Label2 title="Número" :content="params.numero_ganador" v-if="params.numero_ganador && lista == 0"/>
              <Label2 title="Número" :content="params.numero_ganador" v-if="params.numero_ganador && lista == 1"/>
              <Label2 title="Número"
                      :content="params.numero_ganador.substr(0, 2)+'-'+getSign(params.numero_ganador.substr(2, 1))"
                      v-if="params.numero_ganador && lista == 2"/>
              <Label2 title="Número"
                      :content="params.numero_ganador.substr(0, 3)+'-'+getSign(params.numero_ganador.substr(3, 1))"
                      v-if="params.numero_ganador && lista == 3"/>

              <div class="cuenta">
                <Label2 title="Monto" :content="formatCurrency(params.monto_apostado)"/>
                <div class="symbol">x</div>
                <Label2 title="Factor" :content=" Number(params.nfactor).toFixed()"/>
                <div class="symbol">=</div>
                <Label2 title="Premio" :content="formatCurrency(params.premio)"/>
              </div>
              <div class="ganador">
                <Label2 title="Estatus" content="GANADOR"/>
              </div>
              <Label2 title="Caduca" :content="formatDateLong2(params.expiration_date)"/>


            </template>
          </Card>
        </div>

        <div class="venta field col-12 md:col-12">
          <Card>
            <template #title>
              Venta
            </template>
            <template #content>
              <Label2 title="Fecha" :content="params.fecha && formatDateLong2(params.fecha)"/>
              <Label2 title="Hora" :content="params.hora"/>
              <Label2 title="Taquilla" :content="params.taquilla"/>
              <Label2 title="Usuario" :content="params.usuario_vende"/>
            </template>
          </Card>
        </div>

<!--        <div class="venta field col-12 md:col-6">-->
<!--          <Card>-->
<!--            <template #title>-->
<!--              Pago-->
<!--            </template>-->
<!--            <template #content>-->
<!--              <Label2 title="Fecha" :content="params.fecha_pagado && formatDateLong2(params.fecha_pagado)"/>-->
<!--              <Label2 title="Hora" :content="params.hora_pagado"/>-->
<!--              <Label2 title="Taquilla" :content="params.id_taquilla_pago"/>-->
<!--              <Label2 title="Usuario" :content="params.usuario_paga"/>-->
<!--            </template>-->
<!--          </Card>-->
<!--        </div>-->
      </div>
    </div>
  </Dialog>

  <ViewDialog
      :show="displayTicket"
      :ticket="ticket"
      :lista="lista"
      @close="closeViewTicketDialog"/>

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import Label2 from "@/components/misc/Labels/Label2";

import ViewDialog from "@/components/Tickets/Dialogs/ViewDialog";
import TicketService from "@/service/backend/TicketService";

export default {
  name: 'WinnerDetailDialog',
  components: {
    Label2,
    ViewDialog,
  },
  created() {
    this.ticketService = new TicketService();
  },
  mixins: [generalMixin],
  data() {
    return {
      displayTicket: false,
      particular: null,
      ticket: null,
      overlay: false,
    }
  },
  props: {
    show: Boolean,
    header: String,
    game: String,
    lista: String,
    data: Object,
  },
  updated() {
    this.params = this.data && this.data[0];
  },
  methods: {
    closeDialog() {
      this.computedVisible = false;
      this.$emit('close')
    },
    getExpiryDate(data) {
      const dDate = new Date(Date.parse(data.dtransaccion));
      const dDay = Number(dDate.getDate()) + Number(data.caduca);
      return dDate.getFullYear() + '-' + (dDate.getMonth() + 1) + '-' + dDay;
    },
    async openViewTicketDialog(params) {
      params.game = params.animal ? 'animal' : false;
      params.identity = this.identity;
      this.overlay = true;
      this.ticket = await this.ticketService.getTicket(params);
      this.displayTicket = true;
    },
    closeViewTicketDialog() {
      this.displayTicket = false;
      this.overlay = false;
    }
  },
  computed: {
    computedVisible: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close')
      }
    },
    getTicketNumber() {
      return this.header;
    },
  },
};
</script>
<style>
.ticket-dialog {
  min-width: 200px;
}

/*.p-dialog-title {*/
.ticket-dialog .p-dialog-title {
  background-color: #004a49;
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  color: white;
}

::v-deep(.row-accessories) {
  background-color: rgba(0, 0, 0, .15) !important;
}

.view-button {
  /*margin: 10px !important;*/
  width: 130px !important;

}

.title {
  display: flex;
  justify-content: space-between;
}

.details {
  font-size: initial;
  border: 1px solid var(--primary-color);
  padding: 5px 5px 5px 15px;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.symbol {
  margin-top: 15px;
  font-weight: bolder;
}

.cuenta {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 2250px) {
  .cuenta {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

</style>