import BaseService from "./BaseService";

class TicketService extends BaseService {

    async getTicketReport(req) {
        const response = await this.axios.post('/ticket/report', req);
        return response.data;
    }

    async getTicketReportDetail(req) {
        const response = await this.axios.post('/ticket/report-detail', req);
        return response.data;
    }

    async getTableData(req) {
        const response = await this.axios.post('/ticket/table', req);
        return response.data;
    }

    async getTableDataLazy(req) {
        const response = await this.axios.post('/ticket/table-lazy', req);
        return response.data;
    }

    async getWinnerTicketDetail(req) {
        const response = await this.axios.post('/ticket/winner-detail', req);
        return response.data;
    }
    async getTicketDetail(req) {
        const response = await this.axios.post('/ticket/detail', req);
        return response.data;
    }

    async getTicket(req) {
        const response = await this.axios.post('/ticket/view', req);
        return response.data;
    }

    async getAll(req) {
        const response = await this.axios.post('/ticket/all', req);
        return response.data;
    }

    async getToBeCancelled(req) {
        const response = await this.axios.post('/ticket/to-be-cancelled', req);
        return response.data;
    }

    async getToBeCancelledLazy(req) {
        const response = await this.axios.post('/ticket/to-be-cancelled-lazy', req);
        return response.data;
    }

    async cancelTicket(req) {
        const response = await this.axios.post('/ticket/cancel', req);
        return response.data;
    }

    async getCancelled(req) {
        const response = await this.axios.post('/ticket/cancelled', req);
        return response.data;
    }

    async getPaid(req) {
        const response = await this.axios.post('/ticket/paid', req);
        return response.data;
    }
}

export default TicketService;
